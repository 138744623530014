<template>
  <div>
    <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
    ></NavBar>

    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
        <v-row cols="12">
          <v-col cols="2">
            <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
          </v-col>

          <v-col cols="10" style="overflow:scroll; height:90vh;">
            <div style="margin: 10px 20px 20px 50px">
              <div style="display: flex; flex-direction:row; justify-content: space-between;">
                <div style="width:30%;" align="left">
                  <span style="font-weight: 800; font-size: 12pt;padding-left: 8px; text-transform: uppercase; letter-spacing: 1px;">
                     <h3>Featured Speciality Information</h3>
                  </span>
                </div>

                <div style="display:flex;">
                  <div >
                    <span
                      style="font-size: 26pt;
                      display: flex;
                      justify-content: space-between;
                      align-items: end;"
                      >
                      <v-btn
                        append
                        rounded
                        color="primary"
                        style="height:35px; margin-top:3vh;"
                        @click="addFeaturedSpecialityFunction"
                        >

                        <span style="font-size: 18pt;"> + </span>
                        <span style="font-weight: 600;">Add Featured Speciality</span>
                      </v-btn>
                    </span>
                  </div>
                </div>
              </div>

              <div v-if="!isLoading" style="margin-top: 2%; height: 500px; margin-top: 3%;">
                <table class="tableClass"  v-if="featureSpec.length > 0">
                  <thead>
                    <tr>
                      <th class="text-left">Featured Speciality Name</th>
                      <th class="text-left">Banner Position</th>
                      <th class="text-left">Number of Doctors</th>
                      <th class="text-left">Created By</th>
                      <th class="text-left">Availability Status</th>
                      <th class="text-left">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="feature in featureSpec" :key="feature._id">
                      <td style="padding: 5px;"  align="left">
                        {{ feature.title }}
                      </td>
                      <td style="padding: 5px;"  align="left">
                        {{ feature.position }}
                      </td>
                      <td style="padding: 5px;" align="left">
                        {{ feature.noOfDoctors }}
                      </td>
                      <td style="padding: 5px;" align="left">
                        {{ feature.admin_name }}
                      </td>
                      <td>
                        <v-switch
                            color = "primary"
                            :loading = "isEditLoading"
                            :disabled = "isDisabled"
                            dense
                            v-model=feature.availability_status
                            @change="changeAvailabilityStatus(feature._id)"
                        ></v-switch>
                      </td>
                      <td align="left">
                        <div  style="display:flex; flex-direction:row;">
                          <a style="margin:5px;"
                            class="btnClass"
                            depressed
                            icon
                            @click="editFeatureSpec(feature._id)"
                            >
                            <img
                            src="https://d3vsnl8idgwrlw.cloudfront.net/edit.svg"
                            />
                          </a>
                          <a style="margin:5px;padding-right:5px"
                            class="btnClass"
                            depressed
                            icon
                            @click="openDialogDeleteFeatureSpecializationModal(feature._id)"
                            >
                            <img
                            src="https://d3vsnl8idgwrlw.cloudfront.net/delete.svg"
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else>
                  <p>
                    No feature Found !
                  </p>
                </div>
                <div v-if="pagination_list > 1">
                  <v-pagination
                    circle
                    v-model="page"
                    :length="pagination_list"
                    :total-visible="7"
                    @input="findPage(page)"
                  ></v-pagination>
                </div>
              </div>
              <v-progress-circular
                indeterminate
                color="primary"
                v-else
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="deleteFeatureSpecializationModal" persistent max-width="350" :loading="isDeleteDisabled">
            <v-card>
              <v-card-title style="word-break:normal">
                Do you want to delete the Highlight doctors section ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed
                  color="primary" @click="deleteFeatureSpecialization" :disabled = "isDeleteDisabled">
                  Yes
                </v-btn>
                <v-btn depressed
                  color="primary" @click="closeDialogDeleteFeatureSpecializationModal" :disabled = "isDeleteDisabled">
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="editModal" persistent max-width="1000">
            <v-card :loading="isEditLoading">
              <v-card-title style="word-break:normal">
                Edit Featured Speciality
              </v-card-title>
              <v-card-text>
                <div class="rowFlex">
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Title
                    </div>
                    <div style="width: 50%;">
                      {{ feature_title }}
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Banner
                      <br>
                      Recommended size: <b>1029 x 335</b>
                    </div>
                    <div style="width: 50%;">
                      <div class="rowFlex">
                        <div>
                          <v-file-input @change="getPics" v-model="uploadedEnFile" accept="image/jpeg,image/png" />
                        </div>
                        <div>
                          <img height="100px" width="200px" :src = "imageUrl" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Specialization
                    </div>
                    <div style="width: 50%;">
                      <v-select
                        v-model="selected_specialization"
                        solo
                        label="Specialization"
                        :items = "specialization_list"
                        item-text = "specialization_name"
                        item-value = "_id"
                        return-object
                        @change = 'getDoctorList'
                        >
                      </v-select>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Doctors
                    </div>
                    <div style="width: 50%;" class="rowFlex">
                      <div>
                        <v-select
                          v-model="selected_doctor"
                          solo
                          label="Doctor"
                          :items = "doctors_list"
                          item-text = "doctor_name"
                          item-value = "_id"
                          return-object
                          multiple
                          :disabled="!selected_specialization"
                          @change = 'SelectDoctor'
                          >
                        </v-select>
                      </div>
                      <div>
                        <span v-for="value,index in selected_doctor_list" :key="index">{{ value.doctor_name }} <span v-if="index < selected_doctor_list.length-1">,</span> </span>
                      </div>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Banner Position
                    </div>
                    <div style="width: 50%;">
                      <v-text-field type="number" @wheel="$event.target.blur()" v-model="position" min=0 :rules="positionRules" placeholder="Enter banner position">
                      </v-text-field>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Description in English
                    </div>
                    <div style="width: 50%;">
                      <v-textarea
                      placeholder="Enter Description"
                      solo
                      v-model="short_description"
                      ></v-textarea>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Description in Burmese
                    </div>
                    <div style="width: 50%;">
                      <v-textarea
                      placeholder = "Enter Burmese Description"
                      solo
                      v-model="short_description_mm"
                      ></v-textarea>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="editSpec" :disabled="isEditLoading || !((imageUrl != null || attachmentFiles != null ) && short_description.trim().length > 0 && short_description_mm.trim().length > 0 && selected_doctor_list.length > 0 && ( position && position >= 0 ) )">
                  Update
                </v-btn>
                <v-btn depressed
                  color="primary" @click="closeDialogEditFeatureSpecializationModal">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="addModal" persistent max-width="1000">
            <v-card :loading="isEditLoading">
              <v-card-title style="word-break:normal">
                Create Featured Speciality
              </v-card-title>
              <v-card-text>
                <div class="rowFlex">
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Title
                    </div>
                    <div style="width: 50%;">
                      <v-text-field
                        v-model="feature_title"
                        :rules="titleRules"
                        placeholder="Enter Feature Title"
                        dense
                        solo
                        >
                      </v-text-field>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Banner
                      <br>
                      Recommended size: <b>1029 x 335</b>
                    </div>
                    <div style="width: 50%;">
                      <div class="rowFlex">
                        <div>
                          <v-file-input @change="getPics" v-model="uploadedEnFile" accept="image/jpeg,image/png" />
                        </div>
                        <div v-if="imageUrl">
                          <img height="100px" width="200px" :src = "imageUrl" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Specialization
                    </div>
                    <div style="width: 50%;">
                      <v-select
                        v-model="selected_specialization"
                        solo
                        label="Specialization"
                        :items = "specialization_list"
                        item-text = "specialization_name"
                        item-value = "_id"
                        return-object
                        @change = 'getDoctorList'
                        >
                      </v-select>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Doctors
                    </div>
                    <div style="width: 50%;" class="rowFlex">
                      <div>
                        <v-select
                          v-model="selected_doctor"
                          solo
                          label="Doctor"
                          :items = "doctors_list"
                          item-text = "doctor_name"
                          item-value = "_id"
                          return-object
                          multiple
                          :disabled="!selected_specialization"
                          @change = 'SelectDoctor'
                          >
                        </v-select>
                      </div>
                      <div>
                        <span v-for="value,index in selected_doctor_list" :key="index">{{ value.doctor_name }} <span v-if="index < selected_doctor_list.length-1">,</span> </span>
                      </div>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Banner Position
                    </div>
                    <div style="width: 50%;">
                      <v-text-field type="number" @wheel="$event.target.blur()" v-model="position" min=0 :rules="positionRules" placeholder="Enter banner position">
                      </v-text-field>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Description in English
                    </div>
                    <div style="width: 50%;">
                      <v-textarea
                      placeholder="Enter Description"
                      solo
                      v-model="short_description"
                      ></v-textarea>
                    </div>
                  </div>
                  <div class="colFlex">
                    <div style="width: 50%;">
                      Description in Burmese
                    </div>
                    <div style="width: 50%;">
                      <v-textarea
                      placeholder = "Enter Burmese Description"
                      solo
                      v-model="short_description_mm"
                      ></v-textarea>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="addSpecializationFunction" :disabled="isButtonClicked || !(feature_title.trim().length > 0 && attachmentFiles != null && short_description.trim().length > 0 && short_description_mm.trim().length > 0 && selected_doctor_list.length > 0 && ( position && position >= 0 ))">
                  Add
                </v-btn>
                <v-btn depressed
                  color="primary" @click="closeAddModal">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { handleError } from '../../../utils/utils';
import { axios_auth_instance_admin } from '../../../utils/axios_utils';

export default {
  name: "CustomerHome",
  data() {
    return {
      featureSpec: [],
      user_id: "",
      currentUser: "",
      currentUserName: "",
      pageName: "",
      deleteFeatureSpecializationModal: false,
      selectedFeatureSpec : "",
      isLoading : true,
      pagination_list: 0,
      page:1,
      isEditLoading: false,
      testCondition:true,
      isDisabled: false,
      editModal: false,
      addModal: false,
      feature_title: '',
      titleRules: [ (v) => !!v || 'Text is required', (v) => v.length < 160 || 'Cannot exceed 160 characters' ],
      positionRules: [(v) => !!v || 'Position is required', (v) => v >= 0 || 'Cannot be negative' ],
      short_description: '',
      short_description_mm: '',
      uploadedEnFile: null,
      fileTypes:["image/jpeg","image/png"],
      attachmentFiles: null,
      isButtonClicked: false,
      isChangeLoading: false,
      isDeleteDisabled: false,
      specialization_list: [],
      selected_specialization: null,
      doctors_list: [],
      selected_doctor_list: [],
      selected_doctor: null,
      imageUrl: "",
      position: null,
    };
  },
  mounted() {
    this.pageName = "adminSpecPromotion";
    this.currentUser = this.$cookies.get("token");

    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      let readAllhighlightSpecialityRequest = {
        token : this.currentUser,
        typeOfUser : "ADMIN"
      };
      this.isLoading = true;
      //mounted pagewise API call.
      axios.post(process.env.VUE_APP_BACKEND_URL + "/readAllhighlightSpeciality", readAllhighlightSpecialityRequest)
      .then((readAllhighlightSpecialityResponse) => {
        this.featureSpec = readAllhighlightSpecialityResponse.data.data;
        this.featureSpec.forEach((x) => {
          x.availability_status = x.availability_status == 'AVAILABLE';
        });
        this.specialization_list = readAllhighlightSpecialityResponse.data.specializationData;
        this.isLoading = false;
      })
      .catch((readAllhighlightSpecialityError) => {
        window.alert("Something went Wrong");
        console.log(readAllhighlightSpecialityError);
      });
    }
  },
  components: {
    NavBar,
    SideBar
  },
  methods: {
    getDoctorList() {
      this.isEditLoading = true;
      this.doctors_list = [];
      let getDoctorsBySpecializationRequest = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        specialization: this.selected_specialization._id,
      };
      axios.post(
        process.env.VUE_APP_BACKEND_URL + "/getDoctorsBySpecialization", getDoctorsBySpecializationRequest)
      .then((getDoctorsBySpecializationResponse) => {
        this.doctors_list = getDoctorsBySpecializationResponse.data.data;
        this.isEditLoading = false;
      })
      .catch((getDoctorsBySpecializationError) => {
        window.alert("Something went wrong");
        console.log(getDoctorsBySpecializationError);
        this.isEditLoading = false;
      });
    },
    SelectDoctor() {
      this.selected_doctor_list = this.selected_doctor;
    },
    getPics() {
      this.attachmentFiles = null;
      if(this.uploadedEnFile)
      {
        if(this.fileTypes.includes(this.uploadedEnFile.type))
        {
          let reader = new FileReader();
          reader.readAsDataURL(this.uploadedEnFile);
          reader.onload = function(e) {
            // Make a fileInfo Object.
            let img = new Image();
            img.src = e.target.result;
            img.onload = function (x) {
              if( img.width == 1029 && img.height == 335 )
              {
                let fileInfo = {
                  name: this.uploadedEnFile.name,
                  type: this.uploadedEnFile.type,
                  size: Math.round(this.uploadedEnFile.size / 1000)+' kB',
                  base64: reader.result,
                  file: this.uploadedEnFile
                };
                this.attachmentFiles = fileInfo;
                this.imageUrl = URL.createObjectURL(this.uploadedEnFile);
              }
              else
              {
                window.alert("Please upload the image with 1029 x 335 dimension");
                console.log("Provided dimension: ", this.width, " x ", this.height);
                this.uploadedEnFile = null;
                this.attachmentFiles = null;
              }
            }.bind(this);
          }.bind(this);
        } else {
          this.uploadedEnFile = null;
          this.attachmentFiles = null;
          window.alert("Not a valid file");
        }
      }
    },
    editSpec() {
      //api call to update.
      this.isEditLoading = true;
      let editHighLightAdminRequest = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        specId: this.selectedFeatureSpec,
        imageUrl: this.attachmentFiles ? this.attachmentFiles.base64 : this.imageUrl,
        doctorIds: this.selected_doctor_list.map((x) => { return x._id; } ),
        description: this.short_description.trim(),
        description_mm: this.short_description_mm.trim(),
        position: this.position,
        imageChanged: this.attachmentFiles ? "YES" : "NO"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/editHighlightSpeciality", editHighLightAdminRequest)
      .then(() => {
        window.alert("Edited");
        this.$router.go();
      })
      .catch((editHighLightAdminError) => {
        window.alert("Edit failed due to error");
        console.log(editHighLightAdminError);
        this.isEditLoading = false;
      });
    },
    openDialogDeleteFeatureSpecializationModal(specialization_id) {
      this.selectedFeatureSpec = specialization_id;
      this.deleteFeatureSpecializationModal = true;
    },
    closeDialogDeleteFeatureSpecializationModal() {
      this.selectedFeatureSpec = null;
      this.deleteFeatureSpecializationModal = false;
    },
    closeDialogEditFeatureSpecializationModal() {
      this.editModal = false;
      this.feature_title = "";
      this.uploadedEnFile = null;
      this.attachmentFiles = null;
      this.short_description = "";
      this.short_description_mm = "";
      this.imageUrl = "";
    },
    addFeaturedSpecialityFunction() {
      this.addModal = true;
      this.feature_title = "";
      this.uploadedEnFile = null;
      this.attachmentFiles = null;
      this.short_description = "";
      this.short_description_mm = "";
      this.selected_specialization = null;
      this.selected_doctor_list = [];
      this.selected_doctor = [];
      this.imageUrl = null;
    },
    findPage(pageNum) {
      this.isLoading = true;
      let adminViewSpecializationPageRequest = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        page: pageNum,
        limit: 10,
      };
      axios_auth_instance_admin.post("/adminViewSpecializationPage", adminViewSpecializationPageRequest)
      .then((adminViewSpecializationPageResponse) => {
        this.featureSpec = adminViewSpecializationPageResponse.data.specialization_data.docs;
        this.pagination_list = adminViewSpecializationPageResponse.data.specialization_data.totalPages;
        this.isLoading = false;
      })
      .catch((adminViewSpecializationPageError) => {
        handleError(adminViewSpecializationPageError, this.$router, 'adminViewSpecializationPageError', 'AdminLogin', 'token');
      });
    },
    addSpecializationFunction()
    {
      this.isButtonClicked = true;
      if(this.feature_title.trim() && this.uploadedEnFile && this.attachmentFiles && this.short_description.trim() && this.short_description_mm.trim())
      {
        let addHighlightSpecialityRequest = {
          title: this.feature_title.trim(),
          imageUrl: this.attachmentFiles.base64,
          description: this.short_description.trim(),
          description_mm: this.short_description_mm.trim(),
          doctorIds: this.selected_doctor_list.map((x) => { return x._id; } ),
          position: this.position,
          token: this.currentUser,
          typeOfUser: "ADMIN"
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + "/addHighlightSpeciality", addHighlightSpecialityRequest)
        .then(() => {
          window.alert(" Added Successfully ");
          this.feature_title = "";
          this.uploadedEnFile = null;
          this.attachmentFiles = null;
          this.short_description = "";
          this.short_description_mm = "";
          this.position = null;
          this.isButtonClicked = false;
          this.addModal = false;
          this.$router.go();
        }).catch((addHighlightSpecialityError) => {
          if (addHighlightSpecialityError.response.status == 305) {
            window.alert("Title cannot be the same");
          } else {
            window.alert("Error while adding");
          }
          console.log(addHighlightSpecialityError);
          this.isButtonClicked = false;
        });
      } else {
        window.alert("Please fill all the required details");
      }
    },
    closeAddModal() {
      this.addModal = false;
    },
    editFeatureSpec(specialization_id) {
      this.selectedFeatureSpec = specialization_id;
      this.editModal = true;
      this.isEditLoading = true;
      let readParticularhighlightSpecialityRequest = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        specId: this.selectedFeatureSpec
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/readParticularhighlightSpeciality", readParticularhighlightSpecialityRequest)
      .then((readParticularhighlightSpecialityResponse) => {
        this.feature_title = readParticularhighlightSpecialityResponse.data.data[0].title;
        this.short_description = readParticularhighlightSpecialityResponse.data.data[0].description;
        this.short_description_mm = readParticularhighlightSpecialityResponse.data.data[0].description_mm;
        this.isEditLoading = false;
        this.selected_doctor_list = readParticularhighlightSpecialityResponse.data.data[0].doctor_info;
        this.selected_doctor = this.selected_doctor_list;
        this.imageUrl = readParticularhighlightSpecialityResponse.data.data[0].imageUrl;
        this.position = readParticularhighlightSpecialityResponse.data.data[0].position;
      })
      .catch((readParticularhighlightSpecialityError) => {
        window.alert("Change error");
        console.log(changeError);
        this.isEditLoading = false;
      });
    },
    deleteFeatureSpecialization() {
      this.isDeleteDisabled = true;
      let deleteHighLightAdminRequest = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        specId: this.selectedFeatureSpec
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/deleteHighlightSpeciality", deleteHighLightAdminRequest)
      .then(() =>{
        window.alert("Deleted");
        this.isDeleteDisabled = false;
        this.deleteFeatureSpecializationModal = false;
        this.$router.go();
      })
      .catch((deleteHighLightAdminError) =>{
        window.alert("Something went wrong while deleting");
        console.log(deleteHighLightAdminError);
        this.isDeleteDisabled = false;
        this.deleteFeatureSpecializationModal = false;
      });
    },
    changeAvailabilityStatus(highlight_id) {
      this.isEditLoading = true;
      this.isDisabled = true;
      let editHighLightAvailRequest = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        specId: highlight_id
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/editHighlightSpecialityAvailability", editHighLightAvailRequest)
      .then(() => {
        window.alert("Changed");
        this.isEditLoading = false;
        this.isDisabled = false;
      })
      .catch((editHighLightAvailError) => {
        window.alert("Change error");
        console.log(editHighLightAvailError);
        this.isEditLoading = false;
        this.isDisabled = false;
      });
    }
  }
}
</script>

<style scoped>
  .rowFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .colFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0px;
  }
</style>
<style>
td,
th {
  border: 0px;
}
td {
   padding: 2px;
  font-size: 8pt;
  font-weight: 700;
  text-align: left;
  padding-left: 5px;

}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 40px;
  padding-left: 5px;
   text-align: left;
}
tr {
  border-bottom: #d4efff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
  /* margin-left: 10px; */
}
table > tr > td:last-child {
  white-space: nowrap;
}
.btnClass {
  background-color: transparent;
}
/* width */
::-webkit-scrollbar {
  width: 2  px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#facebook{
  display: none;
}
</style>